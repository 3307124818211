<template>
  <div class="stationView">
    <main class="player-content flex-row">
      <section class="flex-1">
        <div class="flex-autorow flex-top flex-stretch player-channel">

  <div class="flex-item flex-1">
    <div class="push-bottom">
      <h4 class="text-nowrap fx fx-slide-left fx-delay-1">Emissions</h4>
      <h5 style="font-size: 0.73rem;" class="text-nowrap fx fx-slide-left fx-delay-1">Liste des émissions de Dance Radio</h5>
    </div>
                <div class="fx fx-slide-up fx-delay-2 pad-top push-bottom">
                     <button v-for="(day, index) in days" :key="index" @click="selectDay(index)" :class="{ 'cta-btn-active': selectedDay === index, 'cta-btn': selectedDay !== index }" type="button">{{ day }}</button>
                </div>

    <ul class="player-tracklist push-bottom">

<li class="fx-slide-left fx-delay-1 card fx flex-row flex-top flex-stretch" v-for="(program, index) in programs[selectedDay]" :key="index">
    <div id="test_background" :style="'position: absolute;display: block;top: 0;right: 0;bottom: 0;left: 0;background-size: cover;background-position: center;z-index: -1;background-image: url( '+program.image+' );border-radius: 7px;'"></div>
    <div class="pad-left">
            <div> <span style="color: #fff;
    font-size: 0.6rem !important;
    padding: 3px 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: -5px;
    margin-left: 0;
    width: auto;
    background-color: rgba(0, 0, 0, 0.1);font-weight: 800;" class="text-bright">{{ program.time }}</span> </div>
          <div> <span style="    text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);" class="text-bright">{{ program.title }}</span> </div>
          <div><span style="font-size: 13px !important;    text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);" class="text-bright">{{ program.description }}</span> </div>
          <div v-if="program.animer"><span style="font-size: 13px !important;    text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);" class="text-bright">Animée par {{ program.animer }}</span> </div>
    </div>
          </li>

<!--
<li class="fx-slide-left fx-delay-1 card fx flex-row flex-top flex-stretch">
<div style="position: absolute;
display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    z-index: -1;
  background-image: url(img/emissions/HHS.png);
  border-radius: 7px;

"></div>
        <div class="pad-left">
            <div> <span style="color: #fff;
    font-size: 0.6rem !important;
    padding: 3px 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: -5px;
    margin-left: 0;
    width: auto;
    background-color: rgba(0, 0, 0, 0.1);font-weight: 800;" class="text-bright">17:00 - 19:00</span> </div>
          <div> <span style="    text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);" class="text-bright">Happy Hours Show</span> </div>
          <div><span style="font-size: 13px !important;    text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);" class="text-bright">Rentre dans la bonne ambiance avec Alain au commande</span> </div>
          <div><span style="font-size: 13px !important;    text-shadow: 0 0 9px rgba(0, 0, 0, 0.75);" class="text-bright">Animée par Alain et Wendy</span> </div>
        </div>
      </li>
-->

    </ul>

  </div>

      </div>
      </section>
    </main>

  </div>
</template>

<script>
import axios from 'axios';
import { mapState  } from 'vuex';
export default {
    data () {
    return {
        days: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
    selectedDay: 0,
    programs: [
      [
        { title: 'Happy Hours Show', image: 'img/emissions/HHS.png', time: '17:00 - 19:00', description: 'Rentre dans la bonne ambiance avec Alain au commande', animer: 'Alain et Wendy' },
        { title: 'Programme 2', image: 'img/emissions/HHS.png', time: '17:00 - 19:00', description: 'Description du Programme 2' },
      ],
      [
        { title: 'Happy Hours Show', image: 'img/emissions/HHS.png', time: '17:00 - 19:00', description: 'Rentre dans la bonne ambiance avec Alain au commande', animer: 'Alain et Wendy' },
      ],      
      [
        { title: 'Happy Hours Show', image: 'img/emissions/HHS.png', time: '17:00 - 19:00', description: 'Rentre dans la bonne ambiance avec Alain au commande', animer: 'Alain et Wendy' },
      ],      
      [
        { title: 'Happy Hours Show', image: 'img/emissions/HHS.png', time: '17:00 - 19:00', description: 'Rentre dans la bonne ambiance avec Alain au commande', animer: 'Alain et Wendy' },
      ],      
      [
        { title: 'Happy Hours Show', image: 'img/emissions/HHS.png', time: '17:00 - 19:00', description: 'Rentre dans la bonne ambiance avec Alain au commande', animer: 'Alain et Wendy' },
      ],      
      [
        { title: 'Vaut Mieux En Rire', image: 'img/emissions/VMER.jpg', time: '20:00 - 23:00', description: 'Ta libre antenne complète fou de l\'actualité du cinéma des questions et bien plus encore pour te faire passer un samedi soir de folie', animer: 'Lucas , Alex , Lise , Maxime , Caroline , Damien , WISSEM , Anais , Alexandre , Raph , Clément , Élise , Alexis , Jason , Adrien et Gautier' },
      ],
      [
        { title: 'Happy Hours Show', image: 'img/emissions/HHS.png', time: '17:00 - 19:00', description: 'Rentre dans la bonne ambiance avec Alain au commande', animer: 'Alain et Wendy' },
      ],
      // ... Ajoute les programmes pour les autres jours de la semaine
    ],
    }
},
  name: "songsHistory",
  computed: {
    ...mapState('nowplaying',{
      songs : state => state.songs,
    }),
    config(){
      return this.$store.getters["playerConfig/getConfig"];
    },
    hasSongs: (songs) => {
      return (!!Array(songs).length);
    }
  },

  methods: {
    selectDay(index) {
      this.selectedDay = index;
    }
  },
   mounted() {
    // Déterminer le jour actuel (0 pour Dimanche, 1 pour Lundi, ..., 6 pour Samedi)
    const currentDay = new Date().getDay();
    
    // Utiliser le jour actuel comme jour par défaut
    this.selectedDay = currentDay === 0 ? 6 : currentDay - 1;
  }
}
</script>

<style scoped>

</style>